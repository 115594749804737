

const teacher = [{
    img: require("../assets/index/teacher/1.png"),
    name: "林銮珠老师",
    size: [
        " • 标榜半岛教育集团教育督导",
        " • 深圳市学前专业委员会成员",
        " • 深圳市龙岗区第四届幼教督学",
        " • 深圳市龙岗区程蓉督学工作室成员",
        " • 深圳市龙岗区优秀幼儿教育工作者",
        " • 国际多元系统评估指导师",
    ],
},
{
    img: require("../assets/index/teacher/2.png"),
    name: "王丽莉老师",
    size: [
        "• 标榜半岛教育集团总园长",
        "• 深圳市优质幼儿园园长",
        "• 十三五课题主持人",
        "• 中国学前教育研究会成员",
        "• 深圳市学前专业委员会成员",
        "• 国际多元系统评估指导师",
    ],
},
{
    img: require("../assets/index/teacher/3.png"),
    name: "刘珊老师",
    size: [
        "• 刘珊博凯幼儿园园长",
        "• 幼儿园高级教师",
        "• 蒙特梭利 CMS高级讲师",
        "• 儿童心理健康咨询师",
    ],
}, {
    img: require("../assets/index/teacher/4.png"),
    name: "徐晶津老师",
    size: [
        '• 香港教育大学教育硕士',
        '• ACEI中国区理事',
        '• 教育研究中心成员',
        '• 香港注册幼儿中心主管',
        '• 世界幼儿教育联会会员',
        '• 香港惠苗协会永久会员',
    ],
}, {
    img: require("../assets/index/teacher/5.png"),
    name: "魏一行老师",
    size: [
        '• 标榜半岛教育集团教研部经理',
        '• 学前教育硕士',
        '• 国内知名连锁早教品牌督导',
        '• 早教托育课程',
        '• 资深教研员、培训师',
        '• 儿童多元智能高级测评师',
    ],
}, {
    img: require("../assets/index/teacher/6.png"),
    name: "黄丽桃老师",
    size: [
        '• 标榜半岛教育集团托育部经理',
        '• 学前教育硕士',
        '• 早托育资深运营督导',
        '• 托育教育专业能力培训讲师',
        '• 0-6岁儿童发育商测评',
    ],
}, {
    img: require("../assets/index/teacher/7.png"),
    name: "钟锦英老师",
    size: [
        '• CMS蒙台梭利认证讲师',
        '• 国家职业技能“保育师”考评员',
        '• 国家职业技能“育婴员”考评员',
        '• 国家职业技能“托育照护”考评员',
    ],
}, {
    img: require("../assets/index/teacher/8.png"),
    name: "苏淑侦老师",
    size: [
        '• 标榜半岛教育集团校企服务部负责人',
        '• 儿童发展与教育心理学硕士',
        '• 教育部1+X婴幼儿照护考评员',
        '• 儿童青少年心理健康辅导师',
        '• 高级家庭教育指导师',
        '• IPA国际注册高级教师',
        ' • 心理咨询师',
    ],
}, {
    img: require("../assets/index/teacher/9.png"),
    name: "易赛男老师",
    size: [
        ' • 标榜半岛教育集团区域督学',
        '• 中山市保利国际幼儿园园长',
        '• 幼儿园一级教师',
        '• 国家心理咨询师',
    ],
}, {
    img: require("../assets/index/teacher/10.png"),
    name: "黄碧华老师",
    size: [
        '• 标榜半岛教育集团区域督学',
        '• 中山市保利国际',
        '• 幼儿园园长',
    ],
}, {
    img: require("../assets/index/teacher/11.png"),
    name: "吴杜平老师",
    size: [
        '• 中山市西区第三幼儿园园长',
        '• 家庭教育高级讲师',
        '• 中山市西区优秀教师',
        '• 中山市西区督导组成员',
    ],
}, {
    img: require("../assets/index/teacher/12.png"),
    name: "陈少清老师",
    size: [
        '• 标榜半岛天品幼儿园园长',
        '• 中山市优秀教师',
        '• 中山市神湾镇优秀教育工作者',
        '• 中山市神湾镇青年协会会员',
    ],
}
]
const kindergarten=[{
    img: require("../assets/integration/kindergarten/1.png"),
    name: "中山火炬高技术产业开发区博凯幼儿园",
},{
    img: require("../assets/integration/kindergarten/2.png"),
    name: "中山市三角镇标榜半岛幼儿园",
},{
    img: require("../assets/integration/kindergarten/3.png"),
    name: "中山市神湾镇标榜半岛天品幼儿园",
},{
    img: require("../assets/integration/kindergarten/4.png"),
    name: "中山市西区第三幼儿园",
},{
    img: require("../assets/integration/kindergarten/5.png"),
    name: "中山市西区第四幼儿园",
},{
    img: require("../assets/integration/kindergarten/6.png"),
    name: "中山市港口标榜半岛幼儿园",
},]

const environment =[
    require("../assets/integration/environment/1.png"),
    require("../assets/integration/environment/2.png"),
    require("../assets/integration/environment/3.png"),
]
const environment1 =[
    require("../assets/integration/environment/4.png"),
    require("../assets/integration/environment/5.png"),
    require("../assets/integration/environment/6.png"),
]
const fusearr = [{
    title:'专业共建',
    img: require("../assets/integration/fuse/icon/1.png"),
    size: [
        '人才订单培养',
        '人才培养体系完善',
        '特色课程构建',
        '技能大赛辅导',    
    ],
},{
    title:'教研开发',
    img: require("../assets/integration/fuse/icon/2.png"),
    size: [
        '教材、精品课等资源开发',
        '课题共研',
        '教学、课程体系改革',
        '师资互聘',       
    ],
},{
    title:'数字校园建设',
    img: require("../assets/integration/fuse/icon/3.png"),
    size: [
        '管理、教学、培训、观摩',
        '智慧教育系统',
        '一体化智慧教室',     
    ],
},{
    title:'智慧实训建设',
    img: require("../assets/integration/fuse/icon/4.png"),
    size: [
        '见习实习合作',
        '实训室和附属园共建',
        '智慧实训基地建设',
        '虚拟仿真实训建设',       
    ],
},{
    title:'学历教学资源服务',
    img: require("../assets/integration/fuse/icon/5.png"),
    size: [
        '现代学徒制合作',
        '学历提升',
        '职业资格资源服务',  
    ],
},{
    title:'社会服务',
    img: require("../assets/integration/fuse/icon/6.png"),
    size: [
        '教师数字素养赋能',
        '社会培训',
        '校企师资交流',
    ],
}]
const alljson = {
    teacher,
    kindergarten,
    environment,
    environment1,
    fusearr
}
export default alljson;